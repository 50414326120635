// данные переключателя типа из страницы с лучшими статьями
export const topTypeList: Array<TypeListItem> = [
  {
    id: Symbol(),
    value: '/top/week/',
    activeValues: ['/top/week/', '/top/'],
    name: 'За неделю',
  },
  {
    id: Symbol(),
    value: '/top/month/',
    activeValues: ['/top/month/'],
    name: 'За месяц',
  },
  {
    id: Symbol(),
    value: '/top/year/',
    activeValues: ['/top/year/'],
    name: 'За год',
  },
];
